import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import pushButton from '../../images/button_push.png';
import buttonUnlocked from '../../images/button_unlocked.gif';
import buttonlocked from '../../images/button_locked.gif';
import iconFolder from '../../images/icon_folder.gif';
import fileIcon from '../../images/icon_file.gif';
import renameButton from '../../images/button_rename.gif';
import button_unzip from '../../images/button_unzip.gif';
import Header from '../common/Header/Header';
import Footer from '../common/Footer/Footer';
import Banner from '../common/Banner/Banner';
import '../common/css/pw_push5.css';
import { getSubDirectories, pushfile, getFileSMSInfo, emptySMSInfo, noDirOrUserFound, deleteFilesAction,
  downloadBulkFilesInZip } from './actions';
import Loader from "../common/Loader";
import Modal from 'react-modal';
import SmsPopup from '../common/Modal/SMS/SmsPopup';
import Unzip from '../common/Modal/Unzip/Unzip';
import NoUserDir from '../common/Modal/NoUserDir';
import { updateGenricSuccessMessageToEmpty } from '../User/actions';
import RenameFolder from '../common/Modal/RenameFolder';
import { MS_CONSOLE_TITLE } from '../utils/Constants';

const Dashboard = ({}) => {
    const [filesLists, setFilesList] = useState([]);
    const msTitle = `${MS_CONSOLE_TITLE} Environment - Media Delivery Console`;
    //const { path } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [disableCheckbox,setDisableCheckbox] = useState(true);
    const [loading,setLoading] = useState(false);
    //Store selected file for popup
    const [selectedFile, setSelectedFile] = useState(false)
    //New state for popup
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    //store the selected file for unzip
    const [selectedFileToUnzip, setSelectedFileToUnzip]= useState(null);
    //New state for unzip popup
    const [showUnzipPopup, setShowUnzipPopup]= useState(false);
    // No User or Directory Modal popup
    const [noUserDirStatus, setNoUserDirStatus]= useState(false);
    // No user found status
    const [noUserDirResp, setNoUserDirResp] = useState([]);
    // check count of directroies
    const [dirCount, setDirCount] = useState(0);
    // delete, download files Array
    const [deleteFilesArray, setDeleteFilesArray] = useState([]); // this variable is used for both download or deleting the files
    //New state for rename popup
    const [showRenamePopup, setShowRenamePopup]= useState(false);
    //store the selected file for rename
    const [selectedFileToRename, setSelectedFileToRename]= useState(null);
    let currentURI = location.pathname;

    let currentURImatches = currentURI.match(/^\/mdc\/auth\/([^\/]+)/);
    let uriPrefex = currentURImatches[0];
    if(currentURImatches == null)
    {
        // Invalid location pathname
    }
    let currentPath = currentURI.replace(`${uriPrefex}`,"");
    currentPath = currentPath.split('/');
    currentPath = currentPath.filter(path => path);
    currentPath = `/${currentPath.join('/')}`
    if(currentPath === "")
    {
      currentPath = '/';
    }
    const directoriesList = useSelector(state => { return state.directoriesReducer.serverResponse });
    const noDirUserInfo = useSelector(state => { return state.directoriesReducer.noUserDirInfo} );

    // No user exists or user doesn't have access to directory access
    useEffect(() =>{
      if(noDirUserInfo?.status_code === 404 && noDirUserInfo?.status === 'Failed')
      {
          setNoUserDirResp(noDirUserInfo)
          setNoUserDirStatus(true);
          setDisableCheckbox(true);
      }
    },[noDirUserInfo])
    
    useEffect(()=>{
      setTimeout(() => {
        dispatch(updateGenricSuccessMessageToEmpty({}));
      }, 5000)
      
      setFilesList(directoriesList?.data);
      setDirCount(directoriesList?.data?.length);
      deSelectAllFiles();
      setLoading(false);
  },[directoriesList]);

    const rootFolderClickHandler = (path, isFile) => {
      setLoading(true);
      
      if(isFile)
      {
        setLoading(false);
        let redirectLocation = (location.pathname).replace('/auth/media/','/preview/');
        window.open(`${redirectLocation}/${path}`);
      }else {
        setFilesList([]);
        navigate(`${location.pathname}/${path}`);
      }
    }
    // when user navigates back using browser's back button, need to detect path changes and get directories
    useEffect(() =>{
      setLoading(true);
      //dispatch(getSubDirectories({dir:currentPath}));
      let splitCurrentPath = currentPath.split('/');
      splitCurrentPath = splitCurrentPath.filter((path) => { return path !=''});
      //console.log(currentPath.split('/'));
      if(currentPath !== '/' && splitCurrentPath.length == 1 && splitCurrentPath[0] == 'ov2')
      {
        setDisableCheckbox(true);
      }else if(currentPath !== '/')
      {
        setDisableCheckbox(false);
      }else{
        setDisableCheckbox(true);
      }
    },[currentPath, dispatch]);
  

    const pushSubmit = async (fileName) => {
      
        if(window.confirm("Are you sure you want push file(s) live?")){
            
            const payload = {
              file: fileName,
              current_path: currentPath,
              update: 1
            };
    
          dispatch(
            pushfile(
            {
              payload
            })
          );
          scrollToTop();
        }

    }

    const closeSMSModalPopup = useCallback((popupState) => {
        setIsPopupOpen(!popupState);
        dispatch(getSubDirectories({dir:currentPath}));
    },[currentPath]);
    
    const closeNoUserDirModalPopup = useCallback((popupState) => {
      setNoUserDirStatus(!popupState)
      dispatch(noDirOrUserFound({}))
    },[])

    const closeUnzipModalPopup = (popupState) => {
        setShowUnzipPopup(!popupState);
        setTimeout(() => {
          dispatch(getSubDirectories({dir:currentPath}));
        }, 2000)
    }

    const displaySMSPopupModal = (filepath) => {
        setSelectedFile(filepath)
        setIsPopupOpen(true);
        dispatch(emptySMSInfo({}));
        dispatch(getFileSMSInfo({ current_path:currentPath, sms_file : filepath }));
        navigate(`${location.pathname}?sms_config=1`);
    }

    const displayUnzipPopupModal = (filepath) => {
      setSelectedFileToUnzip(filepath)
      setShowUnzipPopup(true)
      navigate(`${location.pathname}?unzip=1`);
    }

    const displayRenamePopupModal = (filepath) => {
      setSelectedFileToRename(filepath)
      setShowRenamePopup(true)
      navigate(`${location.pathname}?rename=1`);
    }

    const closeRenameFolderModalPopup = (popupState) => {
      setShowRenamePopup(!popupState);
      dispatch(getSubDirectories({dir:currentPath}));
      scrollToTop();
    }

    const scrollToTop = () =>{
      window.scrollTo({
        top: 0, 
        behavior: 'smooth'
      });
    };

    const customStyles = {
      content: {
       height:'250px',
       top: '-25%',
      },
    };

    const previewcustomStyles = {
      content: {
       height:'450px',
       top: '0%',
      },
    };

    const deleteFiles = (e) => {
      
      if(e.target.checked)
      {
        setDeleteFilesArray([...deleteFilesArray, e.target.value])
      }else{
        setDeleteFilesArray(deleteFilesArray.filter(file => file !== e.target.value));
      }
    }

    const selectAllFiles = (e) => {
      let checkboxArray = document.getElementsByName("fileDelete[]");
      let fileArray = [];
      checkboxArray.forEach((file) => { file.checked = true; fileArray.push(file.value) });
      setDeleteFilesArray([...deleteFilesArray, ...fileArray]);
    }

    const deSelectAllFiles = (e) => {
      let checkboxArray = document.getElementsByName("fileDelete[]");
      checkboxArray.forEach((file) => { setDeleteFilesArray([]); file.checked = false; })
    }

    const deleteSelectedFiles = (e) => {
      e.preventDefault();
      if(deleteFilesArray.length > 0)
      {
        if(window.confirm("This will delete all files selected! Are you sure you want to do this?!")){
            
          dispatch(deleteFilesAction({data : {current_path : currentPath, files : deleteFilesArray }}));
          setDeleteFilesArray([])
          scrollToTop();
        }
      }else{
        alert('Please Select Files to delete');
      }
    }

    return (
      <>
        <Header title={msTitle}/>
        <body>
        <Banner  title={msTitle} currentPath = {currentPath} disableCheckbox={disableCheckbox} dirListCount = { dirCount }/>
        <br />
        <div className='generalcoursecontainer'>
          <table className='myTable' border="1" width="99%" cellSpacing="1" cellPadding="2" >
            <tbody>
              <tr  align="left" valign="top">
                <th   align="center" valign="middle" width="60">Delete</th>
                <th   align="center" valign="middle" width="50">SMS</th>
                <th   align="left" valign="middle"> &nbsp;Name</th>
                <th   align="center" valign="middle" width="80">Rename</th>
                <th   align="center" valign="middle" width="60">Unzip</th>
                <th   align="left" valign="middle" width="100">&nbsp;Size (Bytes)</th>
                <th   align="left" valign="middle" width="120">&nbsp;Last Modified</th>
                <th   align="center" valign="middle" width="80">Push Live</th>
              </tr>
              {loading ? <tr colSpan="8"><Loader/></tr>: (
              filesLists && filesLists.length > 0 && (
                filesLists.map((fileList,index) => {
                  let cellStyle = "cellGreen";
                  if(index % 2 === 0) 
                  {
                    cellStyle = "cellWhite";
                  }
                  let folderIcon = iconFolder;
                  if(fileList['isFile'])
                  {
                    folderIcon = fileIcon
                  }
                  return (<tr align="left" valign="top" key={index}>
                      <td   width="70" className={"checkboxalign " +cellStyle} >
                        <input type="checkbox" name="fileDelete[]" value={fileList['directory']} disabled={disableCheckbox} onClick = {deleteFiles}/>
                      </td>
                      <td width="50" className={"checkboxalign " +cellStyle+ " folderpointer"}> <img alt={''} src={(fileList['enable_sms_protection'] === 'Enabled') ? buttonlocked : buttonUnlocked} 
                      onClick={ () => {
                          if(!disableCheckbox) { displaySMSPopupModal(fileList['directory']);}
                        }
                        
                        } /></td>
                      <td className={cellStyle} nowrap="">
                        <div className="folderpointer" onClick={() => rootFolderClickHandler(fileList['directory'], fileList['isFile'])}>
                          
                        &nbsp;<img alt={''} src={folderIcon} />&nbsp;{fileList['directory']}
                        </div>
                      </td>
                      
                      <td width="80" className={"folderpointer checkboxalign " + cellStyle}>{ !disableCheckbox && <img alt={''} src={renameButton} onClick={() => {
                              displayRenamePopupModal(fileList['directory']);
                            }}/>
                            }
                            </td>
                      <td className={cellStyle}> {fileList['iszip']}
                      {(!disableCheckbox && fileList['iszip'] == true)&&
                           <center>
                            <img alt={''} src={button_unzip} onClick={() => {
                              displayUnzipPopupModal(fileList['directory']);
                            }} className="folderpointer"/>
                           </center>
                      }
                      </td>
                      <td className={cellStyle}>{fileList['size']}</td>
                      <td className={"checkboxalign " + cellStyle}>{fileList['last_modified_date']}</td>
                      <td className={cellStyle}>
                      {!disableCheckbox &&
                        <center>

                          <button onClick={() => pushSubmit(fileList['directory'])} className="formButtonGo">
                            <img alt={''} src={pushButton} />
                          </button>
                        </center>
                        }
                      </td>
                    </tr>)
                })))}
            </tbody>
          </table>
          { (!disableCheckbox && localStorage.getItem('user.role') !== 'Vendor') && 
          <table width="100%">
              <tr align="left" valign="top">
                <td align="left"><button className="cellWhite formButtonGo delete-selected" onClick={selectAllFiles}>Select&nbsp;All</button>
                  <span className="cellWhite">&nbsp;|&nbsp;</span>
                  <button  className="cellWhite formButtonGo delete-selected" onClick={deSelectAllFiles}>Clear&nbsp;All</button></td>
              </tr>
              <tr align="left" valign="top">
                <td align="left"><input type="button" value="Delete Selected" className="formButtonStop" onClick={deleteSelectedFiles}/></td>
              </tr>
            </table>
          }

          <br/>
          
          { isPopupOpen && 
            <Modal
              isOpen = {isPopupOpen}
              onRequestClose={closeSMSModalPopup}
              contentLabel="SMS Popup"
              ariaHideApp={false}
              shouldCloseOnOverlayClick={false}
              >
                <SmsPopup  
                  isOpen = {isPopupOpen}
                  onRequestClose={closeSMSModalPopup}
                  currentPath = {currentPath}
                  smsFile = {selectedFile}
                />
                <Outlet />
            </Modal>
          }

          { showUnzipPopup && 
            <Modal
              isOpen = {showUnzipPopup}
              onRequestClose={closeUnzipModalPopup}
              contentLabel="UnZip Popup"
              ariaHideApp={false}
              style={customStyles}
              shouldCloseOnOverlayClick={false}
              >
                <Unzip  
                  isOpen = {showUnzipPopup}
                  onRequestClose={closeUnzipModalPopup}
                  currentPath = {currentPath}
                  unzipFile = {selectedFileToUnzip}
                />
                <Outlet />
            </Modal>
          }

          { noUserDirStatus && 
            <Modal
              isOpen = {noUserDirStatus}
              onRequestClose={closeNoUserDirModalPopup}
              contentLabel="No User/Dir Found"
              ariaHideApp={false}
              style={customStyles}
              shouldCloseOnOverlayClick={false}
              >
                <NoUserDir  
                  isOpen = {noUserDirStatus}
                  onRequestClose={closeNoUserDirModalPopup}
                  data={noUserDirResp}
                />
                <Outlet />
            </Modal>
          }

          { showRenamePopup && 
              <Modal
              isOpen = {showRenamePopup}
              onRequestClose={closeRenameFolderModalPopup}
              contentLabel="Rename Folder"
              ariaHideApp={false}
              style={customStyles}
              shouldCloseOnOverlayClick={false}
              >
                  <RenameFolder  
                    isOpen = {showRenamePopup}
                    onRequestClose={closeRenameFolderModalPopup}
                    currentPath = {currentPath}
                    renamefolder = {selectedFileToRename}
                  />
                  <Outlet />
              </Modal>
          }

        </div>
        <Footer/>
        </body>
    </>
  )
}

export default Dashboard;